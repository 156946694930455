@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  @apply bg-green-300 text-black;
}

html {
  min-width: 360px;
  scroll-behavior: smooth;
  background-color: black;
}

body {
  @apply font-sans text-lg text-black dark:text-white;
}

.post {
  @apply font-sans text-lg text-black dark:text-white;
}

.book span {
  @apply rounded-md hover:border hover:border-green-500;
}

.post p {
  @apply my-2 text-gray-700 dark:text-gray-400;
}

.post hr {
  @apply border-gray-300 dark:border-gray-800;
}

.post h1 {
  @apply text-3xl dark:text-white;
}

.post h2 {
  @apply mt-4 mb-2 text-2xl font-semibold;
}

.post h3 {
  @apply mt-4 mb-2 text-xl font-semibold;
}

.post mark {
  @apply px-1 bg-yellow-200 rounded-md;
}

.post blockquote {
  @apply my-2 border-l-4 border-red-400 pl-4;
}

.post .img {
  @apply rounded-md my-4;
}

.post iframe {
  @apply w-full h-96 rounded-md my-4;
}

.post figcaption {
  @apply mt-1 text-base text-center mb-4;
}

.post figure .kg-bookmark-container {
  @apply rounded-md bg-dark-1 border border-gray-900 shadow-lg flex flex-row justify-between hover:border-green-500;
}

.post figure .kg-bookmark-content {
  @apply flex flex-col max-w-md p-3;
}

.post figure .kg-bookmark-title {
  @apply text-gray-300 font-semibold text-lg;
}

.post figure .kg-bookmark-title {
  @apply text-gray-300 font-semibold text-lg;
}

.post figure .kg-bookmark-description {
  @apply text-gray-500 text-base mt-1;
}

.post figure .kg-bookmark-metadata {
  @apply hidden;
}

.post figure .kg-bookmark-thumbnail img {
  @apply w-44 h-full object-cover m-0 p-0 rounded-tl-none rounded-bl-none;
}

.post ul li {
  @apply list-disc list-inside text-gray-700 dark:text-gray-400;
}

.post span code {
  @apply leading-loose;
}

.post code span {
  @apply font-light text-base leading-tight;
}

.post [notion-color='green'] code {
  @apply text-green-500 bg-green-300 dark:bg-green-500 dark:text-green-300 bg-opacity-20 dark:bg-opacity-20 rounded-md text-base py-1 px-2 font-medium;
}

.post [notion-color='red'] code {
  @apply text-red-500 bg-red-300 dark:bg-red-500 dark:text-red-300 bg-opacity-20 dark:bg-opacity-20 rounded-md text-base py-1 px-2 font-medium;
}

.post [notion-color='yellow'] code {
  @apply text-yellow-500 bg-yellow-300 dark:bg-yellow-500 dark:text-yellow-300 bg-opacity-20 dark:bg-opacity-20 rounded-md text-base py-1 px-2 font-medium;
}

.post [notion-color='blue'] code {
  @apply text-blue-500 bg-blue-300 dark:bg-blue-500 dark:text-blue-300 bg-opacity-20 dark:bg-opacity-20 rounded-md text-base py-1 px-2 font-medium;
}

.post [notion-color='purple'] code {
  @apply text-purple-500 bg-purple-300 dark:bg-purple-500 dark:text-purple-300 bg-opacity-20 dark:bg-opacity-20 rounded-md text-base py-1 px-2 font-medium;
}

/* EXECEPTIONS BY ID */
.post #todo p {
  @apply my-2 text-gray-900 dark:text-gray-200;
}
